<template>
  <div class="my-4">
    <h2>
      Almacenes
      <v-btn
        class="mx-4 py-4"
        fab
        small
        color="primary"
        @click="showForm = !showForm"
      >
        <v-icon dark>
          {{ this.showForm ? 'mdi-minus' : 'mdi-plus' }}
        </v-icon>
      </v-btn>
    </h2>
    <v-row
      justify="center"
      class="px-2 mx-4 my-6"
    >
      <v-card
        v-if="showForm"
        class="px-4 py-4"
        max-width="300"
      >
        <p class="text-primary">
        <!-- {{ this.edit ? 'Modificar' : 'Nuevos' }} Datos de Facturación: -->
        </p>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="newAlmacen.nombre_almacen"
                label="Nombre del Almacén"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
            >
              <v-select
                v-model="newAlmacen.id_sucursal"
                label="Sucursal"
                :items="sucursales"
                item-text="nombre_sucursal"
                item-value="id"
                :rules="[rules.required]"
                dense
                solo
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            :disabled="!valid"
            color="primary"
            @click="sendAlmacen"
          >
            {{ buttonAction }}
          </v-btn>
          <v-btn
            color="primary"
            @click="cleanForm"
          >
            limpiar campos 
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>

    <v-row
      class="my-4"
      justify="center"
    >
      <v-card
        v-for="almacen in almacenes"
        :key="almacen.id"
        max-width="300"
        class="mx-4 my-4"
      >
        <v-card-text>
          <p class="display-1 text--primary">
            {{ almacen.nombre_almacen }}
          </p>
          <div>
            Sucursal: {{ almacen.sucursal.nombre_sucursal }}<br>
            Distribuidor: {{ almacen.distribuidor.nombre_distribuidor }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            text
            color="primary"
            @click="editAlmacen(almacen)"
          >
            Editar
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="deleteAlmacen(almacen.id)"
          >
            Eliminar 
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>

  </div>
  
</template>

<script>
import { validationMixin } from '@/mixins/formsValidations.js'
import { ApiAuth } from '@/api_utils/axios-base'

export default {
  mixins: [validationMixin],
  props: {
    sucursales: Array,
    userData: Object,
    almacenes: Array,
    distriId: {
      type:Number,
    }
  },
  data() {
    return {
      newAlmacen: {
        nombre_almacen: null,
        id_sucursal: null,
        id_distribuidor: null,
        is_predeterminado: false
      },
      showForm: false,
      valid: true,
      edit: false,
      modifyAlmacen: null,
    }
  },
  computed: {
    buttonAction() {
      return this.edit ? "Modificar" : "Agregar"
    },

  },
  methods: {
    async sendAlmacen() {
      if(!this.$refs.form.validate()) {
        return
      } else if(this.edit) {
        try {
          this.$store.commit('setLoading', true)

          let respModify = await ApiAuth.put(`/inventarios/api/almacenes/${this.modifyAlmacen}/`,
            this.newAlmacen
          )
          this.showForm = false
          setTimeout(() => { this.$store.commit('setLoading', false)}, 500)

          this.cleanForm()

        } catch(error) {
          console.error("error modifying almacen")
        }
        
      } else {
        try {
          this.$store.commit('setLoading', true)
          this.newAlmacen.id_distribuidor = this.distriId

          let responseAlmacen = await ApiAuth.post('/inventarios/crear-almacen', this.newAlmacen)
          
          let relacionAlmacenUsuario = {
            id_usuario: this.userData.id,
            id_almacen: responseAlmacen.data.id_almacen
          }
          let responseRelacionUsuario = await ApiAuth.post('/inventarios/agregar-usuario-almacen', relacionAlmacenUsuario)
          this.$store.commit('setLoading', false)

        } catch(error) {
          console.error("error creating almacen")
        }
      }
    },
    editAlmacen(almacen) {
      this.modifyAlmacen = almacen.id
      this.newAlmacen.nombre_almacen = almacen.nombre_almacen
      this.newAlmacen.id_sucursal = almacen.sucursal.id

      this.edit = true
      this.showForm = true
    },
    async deleteAlmacen(almacenId) {
      this.$store.commit('setLoading', true)

      let response = await ApiAuth.delete(`/inventarios/api/almacenes/${almacenId}/`)
      setTimeout(() => { this.$store.commit('setLoading', false)}, 500)
    },
    cleanForm() {
      Object.keys(this.newAlmacen).forEach(k => this.newAlmacen[k]= "")
      this.edit = false
    },
  },
}
</script>